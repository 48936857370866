
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import { Info, ResourcesLocation } from '@/types/ordinarySeedling'
import { initWangEdit } from '@/utils/wangEdit'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { MapLocation, UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})

export default class OrdinarySeedlingAdd extends Vue {
  private info: Info = {
    projectId: '',
    seedlingType: '',
    plantName: '',
    plantDate: '',
    treeAge: '',
    standard: '',
    isFamous: '0',
    resourcesLocationList: [],
    seedlingIntroduce: '',
    resourceList: []
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    seedlingType: [
      { required: true, message: '请选择苗木类型', trigger: 'change' }
    ],
    plantName: [
      { required: true, message: '请输入苗木名称', trigger: 'change' }
    ],
    plantDate: [
      { required: true, message: '请选择栽种日期', trigger: 'change' }
    ],
    resourcesLocationList: [
      { required: true, message: '请选择位置坐标及数量', trigger: 'change' }
    ],
    resourceList: [
      { required: true, message: '请选择苗木图片', trigger: 'change' }
    ],
    seedlingIntroduce: [
      { required: true, message: '请输入苗木介绍', trigger: ['change', 'blur'] }
    ]
  }

  // 苗木类别
  private typeList = []

  // 默认展示苗木介绍
  activeName = '0'
  // 富文本实例
  private seedlingIntroduce: any = null
  private submitShow = false
  private resourcesLocationList = []

  get projectList () {
    return this.$store.state.projectList
  }

  get seedlingId () {
    return this.$route.params.seedlingId
  }

  created () {
    this.getTypeList()
    this.init()
    this.seedlingId && this.getDetail()
  }

  destroyed () {
    // 销毁编辑器
    this.seedlingIntroduce.destroy()
    this.seedlingIntroduce = null
  }

  // 查询苗木类别列表
  getTypeList () {
    this.$axios.get(this.$apis.record.selectSeedlingTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
    this.$nextTick(() => {
      (this.$refs.info as ElForm).clearValidate()
    })
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.seedlingIntroduce = initWangEdit('#seedlingIntroduce', { placeholder: '请输入植物介绍' })
      this.seedlingIntroduce.create()
      // 动态监听富文本改变
      this.seedlingIntroduce.config.onchange = (html: string) => {
        this.info.seedlingIntroduce = this.seedlingIntroduce.txt.html()
        if (html) {
          ;(this.$refs.info as ElForm).clearValidate()
        }
      }
    })
  }

  // 编辑
  getDetail () {
    this.$axios.get(this.$apis.seedling.selectSeedlingBySeedlingId, {
      seedlingId: this.seedlingId
    }).then(res => {
      if (res) {
        this.info = res
        this.resourcesLocationList = res.resourcesLocationList
        this.seedlingIntroduce.txt.html(this.info.seedlingIntroduce || '')
      }
    })
  }

  locationChange (list: ResourcesLocation[]) {
    this.info.resourcesLocationList = list
  }

  onSubmit () {
    ;(this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const url = this.seedlingId ? this.$apis.seedling.updateSeedling : this.$apis.seedling.insertSeedling
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.back()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
